import { AlpineComponent } from 'wly-statamic-theme-extensions';

export class SearchComponent extends AlpineComponent {
    public searchHeight: number = 0;
    public style = {};
    public searchHasRun: boolean = false;
    public searchTerm: string = '';
    public results: Array<{
        title: string;
        blocks: string;
        search_score: number;
        search_snippets: Record<string, Array<string>>;
        url: string;
        collection: string;
    }> = [];

    init() {
        this.$watch('searchVisible', (visible) => {
            visible && this.onSearchOpened();

            if (visible) {
                document.body.classList.add('search-open');
            } else {
                document.body.classList.remove('search-open');
            }
        });

        window.addEventListener('resize', () => this.calculateHeight());
    }

    calculateHeight() {
        this.searchHeight = window.innerHeight - this.$el.offsetTop;
        this.applyStyle();
    }

    onSearchOpened() {
        this.results = [];
        this.searchTerm = '';
        this.searchHasRun = false;
        this.$refs.searchInput?.focus();
        this.calculateHeight();
    }

    runSearch() {
        fetch(`/!/search?query=${this.searchTerm}`)
            .then((res) => res.json())
            .then((data) => {
                this.results = data;
                this.searchHasRun = true;
            });
    }

    applyStyle() {
        this.style = {
            'max-height': `${this.searchHeight}px`,
        };
    }
}
