import { AlpineComponent } from 'wly-statamic-theme-extensions';

export class ShowMoreComponent extends AlpineComponent {
    public isExpanded: boolean = false;
    public exceedsHeight: boolean = false;
    public maxHeight: number = 100;
    private contentEl = null

    init() {
        this.contentEl = this.$refs.contentContainer;
        this.maxHeight = this.contentEl.dataset?.maxheight || this.maxHeight;

        this.refresh();
    }

    refresh() {
        this.resetStyle();
        this.calculateAdjustedMaxHeight();
        this.calculateExceedsHeight()

        if (this.exceedsHeight) {
            this.applyStyle();
        }
    }

    calculateAdjustedMaxHeight() {
        const elementStyles = window.getComputedStyle(this.contentEl);
        const lineHeight = Number(elementStyles.getPropertyValue('line-height').replace('px', ''));

        this.maxHeight = this.maxHeight - (this.maxHeight % lineHeight)
    }

    calculateExceedsHeight() {
        const clientRect = this.contentEl.getBoundingClientRect();
        this.exceedsHeight = clientRect.height > this.maxHeight;
    }

    toggleVisibility() {
        if (this.isExpanded) {
            this.applyStyle();
        }
        else {
            this.resetStyle();
        }

        this.isExpanded = !this.isExpanded;
    }

    applyStyle() {
        this.contentEl.style.maxHeight = this.maxHeight + 'px';
    }

    resetStyle() {
        this.contentEl.style.maxHeight = 'none';
    }
}
