export default () => {
    if (!document.querySelector('.baguettebox--open')) {
        return;
    }

    import('baguettebox.js').then((module) => {
        const baguetteBox = module.default;

        baguetteBox.run('.baguettebox--open', {
            noScrollbars: true,
            async: true,
            titleTag: true,
        });
    });
};
