import { RunThemeExtensions } from 'wly-statamic-theme-extensions';
import { PageHeaderComponent } from '@/alpine/components/header';
import { SearchComponent } from '@/alpine/components/search';
import { FormComponent } from '@/alpine/components/form';
import { SwiperComponent } from '@/alpine/components/swiper';
import { ShowMoreComponent } from '@/alpine/components/showmore';

import './scripts/index';

RunThemeExtensions({
    vue: {
        //
    },
    alpine: {
        components: {
            PageHeader: PageHeaderComponent,
            Form: FormComponent,
            Search: SearchComponent,
            Swiper: SwiperComponent,
            ShowMore: ShowMoreComponent,
        },
    },
    menu: {
        //
    },
});
