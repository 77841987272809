import { AlpineComponent } from 'wly-statamic-theme-extensions';

export class PageHeaderComponent extends AlpineComponent {
    public scrolled: boolean = false;
    public height: number = 0;
    public offset: number = 0;

    scrolledToOffset() {
        return this.offset < window.scrollY;
    }

    init() {
        this.offset = 10;

        document.addEventListener(
            'scroll',
            () => {
                this.scrolled = this.scrolledToOffset();
            },
            { passive: true },
        );

        document.addEventListener(
            'resize',
            () => {
                this.offset = window.innerHeight * 0.8;
                this.scrolled = this.scrolledToOffset();
            },
            { passive: true },
        );
    }
}
